import { ImportCentrelinkFileDialog } from "@app/products/property/centrelink-customer-confirmation/view-responses/components/action-bar/dialogs/import-centrelink-file/_index";
import { ImportCentrelinkFileMode } from "@app/products/property/centrelink-customer-confirmation/view-responses/components/action-bar/dialogs/import-centrelink-file/model";
import { EPrivilegesWorkflow } from "@app/products/property/model";
import { PrivilegeWrapper } from "@common/hocs/privileges/privileges-wrapper";
import { ePermissionPrivilegeTypeCheck } from "@common/stores/products/util";
import { CCNavButton } from "@components/cc-nav-button/_index";
import React, { useState } from "react";

export const ImportResponseButton = () => {
  const [showImportResponseFileDialog, setShowImportResponseFileDialog] =
    useState<boolean>(false);

  return (
    <>
      <PrivilegeWrapper
        dialogType={ePermissionPrivilegeTypeCheck.Dialog}
        privilegesEnum={EPrivilegesWorkflow.Centrelink_Import_Response}
      >
        <CCNavButton
          title="Import response"
          onClick={() => setShowImportResponseFileDialog(true)}
        />
      </PrivilegeWrapper>
      {showImportResponseFileDialog && (
        <ImportCentrelinkFileDialog
          onClose={() => {
            setShowImportResponseFileDialog(false);
          }}
          ImportFileMode={ImportCentrelinkFileMode.Response}
        />
      )}
    </>
  );
};
