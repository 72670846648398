import {
  postImportRejectionFile,
  postImportResponseFile,
} from "@app/products/property/centrelink-customer-confirmation/view-responses/components/action-bar/dialogs/import-centrelink-file/api";
import { ImportCentrelinkFileRestrictions } from "@app/products/property/centrelink-customer-confirmation/view-responses/components/action-bar/dialogs/import-centrelink-file/config";
import { ImportCentrelinkFileMode } from "@app/products/property/centrelink-customer-confirmation/view-responses/components/action-bar/dialogs/import-centrelink-file/model";
import { requiredCentrelinkFileValidator } from "@app/products/property/centrelink-customer-confirmation/view-responses/components/action-bar/dialogs/import-centrelink-file/util";
import { isSuccessResponse } from "@common/apis/util";
import {
  CCLocalNotification,
  ICCLocalNotificationHandle,
} from "@components/cc-app-notification/_index";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCDialog } from "@components/cc-dialog/_index";
import { CCLabel } from "@components/cc-label/_index";
import { CCTooltip } from "@components/cc-tooltip/_index";
import CCUploadFile from "@components/cc-upload-file/_index";
import { Button } from "@progress/kendo-react-buttons";
import { Field, Form, FormRenderProps } from "@progress/kendo-react-form";
import {
  UploadOnAddEvent,
  UploadOnRemoveEvent,
} from "@progress/kendo-react-upload";
import { isNil } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useRef, useState } from "react";

interface IImportCentrelinkFileDialogProps {
  onClose: () => void;
  ImportFileMode: ImportCentrelinkFileMode;
}

export const ImportCentrelinkFileDialog = observer(
  ({ onClose, ImportFileMode }: IImportCentrelinkFileDialogProps) => {
    const notificationRef = useRef<ICCLocalNotificationHandle | null>(null);

    const { pushNotification } = useCCAppNotificationStore();

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const handleSubmit = async (data: any) => {
      setIsLoading(true);
      if (!isNil(data?.File)) {
        const file = data?.File[0].getRawFile();
        const formData = new FormData();
        formData.append("file", file);
        let response;
        if (ImportFileMode === ImportCentrelinkFileMode.Rejection) {
          response = await postImportRejectionFile(formData);
        } else {
          response = await postImportResponseFile(formData);
        }
        if (
          isSuccessResponse(response) &&
          response?.data &&
          response?.data?.IsSuccess
        ) {
          onClose();
          pushNotification({
            title:
              response?.data?.SuccessMessage ??
              `Import Centrelink ${ImportFileMode} File successfully.`,
            type: "success",
          });
        } else {
          notificationRef.current?.pushNotification({
            title:
              response?.data?.ErrorMessage ??
              `Import Centrelink ${ImportFileMode} File failed.`,
            type: "error",
            autoClose: false,
          });
        }
      }
      setIsLoading(false);
    };
    return (
      <Form
        onSubmit={(data: any) => {
          handleSubmit(data);
        }}
        render={(formRenderProps: FormRenderProps) => {
          const { valueGetter, onChange, onSubmit, modified, valid } =
            formRenderProps;

          return (
            <CCDialog
              titleHeader={`Import ${ImportFileMode}`}
              onClose={onClose}
              maxWidth="30%"
              maxHeight="35%"
              bodyElement={
                <div className="cc-form-cols-1">
                  <CCLocalNotification ref={notificationRef} />
                  <div className="cc-field">
                    <CCLabel
                      title="Upload file"
                      isMandatory
                      customTooltip={
                        <CCTooltip
                          type="custom"
                          position="auto"
                          content=" "
                          customTemplate={
                            <div>&#8226; Allowed file type: .xml</div>
                          }
                        >
                          <i className="fa fa-info-circle ml-1 text-accent" />
                        </CCTooltip>
                      }
                    />
                    <Field
                      name={"File"}
                      component={CCUploadFile}
                      accept={ImportCentrelinkFileRestrictions.ACCEPT_FORMAT}
                      autoUpload={false}
                      batch={false}
                      multiple={false}
                      withCredentials={false}
                      files={valueGetter("File")}
                      restrictions={{
                        allowedExtensions:
                          ImportCentrelinkFileRestrictions.ACCEPT_FORMAT,
                      }}
                      onAdd={(event: UploadOnAddEvent) => {
                        onChange("File", {
                          value: event.newState,
                        });
                      }}
                      onRemove={(event: UploadOnRemoveEvent) => {
                        onChange("File", {
                          value: event.newState,
                        });
                      }}
                      showActionButtons={false}
                      validator={requiredCentrelinkFileValidator}
                    />
                  </div>
                </div>
              }
              footerElement={
                <div className="cc-dialog-footer-actions-right">
                  <Button
                    className="cc-dialog-button"
                    themeColor="primary"
                    disabled={!modified || !valid || isLoading}
                    onClick={onSubmit}
                    iconClass={isLoading ? "fas fa-spinner fa-spin" : ""}
                  >
                    Finish
                  </Button>
                </div>
              }
            />
          );
        }}
      />
    );
  }
);
