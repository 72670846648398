import { postProduceRequestFile } from "@app/products/property/centrelink-customer-confirmation/view-request-file/components/action-bar/buttons/produce-request-file/api";
import { EPrivilegesWorkflow } from "@app/products/property/model";
import { isSuccessResponse } from "@common/apis/util";
import { PrivilegeWrapper } from "@common/hocs/privileges/privileges-wrapper";
import { ePermissionPrivilegeTypeCheck } from "@common/stores/products/util";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";

export const ProduceRequestFileButton = observer(() => {
  const [isLoading, setIsLoading] = useState(false);
  const { pushNotification } = useCCAppNotificationStore();
  const { isEmptyData } = useCCProductListViewStore();

  const handleProduceRequestFile = async () => {
    setIsLoading(true);
    const response = await postProduceRequestFile();
    if (isSuccessResponse(response) && response.data) {
      pushNotification({
        title:
          response?.data?.SuccessMessage ??
          "Your Centrelink customer confirmation produce requests file job has been added to the job queue, and you will be notified on completion via an email.",
        type: "success",
      });
    } else {
      pushNotification({
        title:
          response?.data?.SuccessMessage ??
          "Your Centrelink customer confirmation produce requests file failed.",
        type: "error",
        autoClose: false,
      });
    }
    setIsLoading(false);
  };

  return (
    <PrivilegeWrapper
      dialogType={ePermissionPrivilegeTypeCheck.Dialog}
      privilegesEnum={EPrivilegesWorkflow.Centrelink_ProduceRequestFile}
    >
      <CCNavButton
        onClick={handleProduceRequestFile}
        disabled={isLoading || isEmptyData}
        title="Produce request file"
        isLoading={isLoading}
      />
    </PrivilegeWrapper>
  );
});
